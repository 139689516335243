import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Image } from 'antd';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import Personal from './BorrowerDetail/Personal';
// import Assets from './BorrowerDetail/Assets';
// import Expenses from './BorrowerDetail/Expenses';
// import Liabilities from './BorrowerDetail/Liabilities';
// import Monthly from './BorrowerDetail/Monthly';
// import Documents from './BorrowerDetail/Documents';
// import Applicationlogs from '../LoanApplication/ApplicantDetail/Applicationlogs';
// import moment from 'moment';
import StepBar from './StepBar';
import Attendance from './BorrowerDetail/Attendance';
import TxnDetail from './BorrowerDetail/TxnDetail';
import Wallet from './BorrowerDetail/Wallet';
import { getApi } from '../service/adminService';
import toast, { Toaster } from 'react-hot-toast';
import PaginationComponent from '../../PaginationComponent';
import Loader from '../../components/Loader/loader';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { TimeZone } from '../../components/TimeZone/TimeZone';

const EmployeeDetails = (props) => {
    const [bank, setBank] = useState([])
    const [employ, setEmploy] = useState([])
    const [income, setIncome] = useState([])
    const [loan, setLoan] = useState([])
    const [pay, setPay] = useState([])
    const [photo, setPhoto] = useState([])
    const [signature, setSignature] = useState([])
    const [tin, setTin] = useState([])
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
    const [employeeDetail, setEmployeeDetail] = useState(Object);
    const [borrowerAPI, setborrowerAPI] = useState(false)
    const { id } = useParams();
    const location = useLocation();
    const admin = JSON.parse(localStorage.getItem("admin"));
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [adminData, setDsaData] = useState(localStorage.getItem("admin"));
    const [token, setToken] = useState(JSON.parse(adminData)?.admin_token);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [EMIdata, setEMIdata] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const [sortedBy, setSortedBy] = useState("id")
    const [orderBy, setOrderBy] = useState('desc')
    const [loader, setloader] = useState(true)
    const navigate = useNavigate();

    const GetEmployeeData = async (token) => {
        // const response = await ViewEmployeeData(token, id)
        if (location?.pathname === `/admin/applicant_details/${id}`) {
            console.log("loooaaannss")
            const response = await getApi(`/user-loan-application/${id}`)
            if (response.status === true) {
                setEmployeeDetail(response.data.userData)
                setBank(response?.data?.docsData.BANK_STATEMENTS)
                setEmploy(response?.data?.docsData.EMPLOYMENT_LETTER)
                setIncome(response?.data?.docsData.INCOME)
                setLoan(response?.data?.docsData.LOAN_STATEMENTS)
                setPay(response?.data?.docsData.PAYSLIPS)
                setPhoto(response?.data?.docsData.PHOTO_ID)
                setSignature(response?.data?.docsData.SIGNATURE)
                setTin(response?.data?.docsData.TIN_LETTER)
            } else {
                console.log(response)
            }
        } else {
            const response = await getApi(`/get-employee-byId/${id}`)
            console.log("ooppss")
            setborrowerAPI(true)
            if (response.status === true) {
                setEmployeeDetail(response.data)
                setBank(response.document.BANK_STATEMENTS)
                setEmploy(response.document.EMPLOYMENT_LETTER)
                // console.log(response.document.EMPLOYMENT_LETTER)
                setIncome(response.document.INCOME)
                setLoan(response.document.LOAN_STATEMENTS)
                setPay(response.document.PAYSLIPS)
                setPhoto(response.document.PHOTO_ID)
                setSignature(response.document.SIGNATURE)
                setTin(response.document.TIN_LETTER)
            } else {
                console.log(response)
            }
        }
    }


    useEffect(() => {
        GetEmployeeData(admin.admin_token, id);
    }, []);

    // For Personal Tab Start

    function calculateAge(dob) {
        if (!dob) {
            return '';
        }

        const today = new Date();
        const birthDate = new Date(dob);

        let age = today.getFullYear() - birthDate.getFullYear();

        const currentMonth = today.getMonth();
        const birthMonth = birthDate.getMonth();
        const currentDate = today.getDate();
        const birthDay = birthDate.getDate();

        if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDate < birthDay)) {
            age--;
        }
        return age.toString();
    }

    const age = calculateAge(employeeDetail?.date_of_birth && employeeDetail?.date_of_birth);

    const viewActivityLogs = async (token) => {
        // const response = await ViewActivityLogs(token, id, search, pagesCount, sortedBy, orderBy)
        const response = await getApi(`/get-loan-application-activity/${id}`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy })

        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data.rows)
                setTotalPagesCount(response.data.count)

                setloader(false)
            }, 2000);
            setloader(true)
        } else if (response.message == 'Session expired') {
            localStorage.removeItem('admin');
            navigate(process.env.REACT_APP_ADMIN_PRIFIX + '/login')
        } else if (response.message == "Access denied! you're not allowed to access this tab") {
            toast.error(response.message)
            navigate('/admin/dashboard')
        } else {
            console.log(response)
        }
    }

    useEffect(() => {
        if (location?.pathname === `/admin/applicant_details/${id}`) {
            viewActivityLogs(token, search, pagesCount)
        }
    }, [search, pagesCount, sortedBy, orderBy])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }
    const viewApplicantEMI = async (token) => {
        const response = await getApi(`/get-loan-emi-data/${id}`)
        console.log(response, "_____________")
        if (response.status == true) {
            setEMIdata(response.data)
        } else if (response.message == 'Session expired') {
            localStorage.removeItem('admin');
            navigate(process.env.REACT_APP_ADMIN_PRIFIX + '/login')
        } else {
            console.log(response)
        }
    }

    useEffect(() => {
        if (location?.pathname === `/admin/applicant_details/${id}`) {
            viewApplicantEMI()
        }
    }, [])

    const annualSalary = parseFloat(employeeDetail?.annual_salary ? employeeDetail?.annual_salary : 0);
    const otherIncome = parseFloat(employeeDetail?.other_income ? employeeDetail?.other_income : 0);

    const Total_Monthly_Income = ((annualSalary / 12) + otherIncome).toFixed(2);

    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header my-3 py-2">
                    <div className="page-leftheader">
                        <h3 className="page-title mb-0 text-primary">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb ">
                                    {borrowerAPI ? <><li className="breadcrumb-item ">  <Link className='breadcrumbslink' to={prefix + '/borrower'}>Borrower</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Details </li></> :
                                        <> <li className="breadcrumb-item ">  <Link className='breadcrumbslink' to={prefix + '/ewa_advance_application'}>Manage EWA Advance</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Details </li></>}
                                </ol>
                            </nav>
                        </h3>
                    </div>{borrowerAPI ? <Link to={prefix + '/borrower'} >Back</Link> : <Link to={prefix + '/ewa_advance_application'} >Back</Link>}
                    <div style={{ width: "100%" }}>

                        <div className="col-md-12 tabsbtm">
                            <Tabs
                                defaultActiveKey="personal"
                                id="fill-tab-example"
                                className="mb-3"
                                fill
                            >
                                <Tab eventKey="personal" title="Personal"  >
                                    {/* <Personal /> */}
                                    <div className="main-container container-fluid px-0">
                                        <div className="page-header my-3 py-2">
                                            <div className="modal-body application-modal px-0">
                                                <div className="row">
                                                    <div className="col-lg-12 col-xl-12">
                                                        <div className="">
                                                            <div className="main-content-body main-content-body-contacts">
                                                                <h6>Borrower Information</h6>
                                                                <div className='btn_group' style={{}}>
                                                                </div>
                                                                <div className="main-contact-info-body">
                                                                    <div className="media-list p-0">
                                                                        <div className="media py-4 mt-0">
                                                                            <div className="media-body">
                                                                                {/* require_loan_amount */}
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-file"></i> </div>
                                                                                    <div className="w-70"> <label>Tin :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.tin ? employeeDetail?.tin : '--'}</span> </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-address-card fa-fw"></i> </div>
                                                                                    <div className="w-70"> <label>FNPF: </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.fnpf ? employeeDetail?.fnpf : '--'}</span> </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-user"></i> </div>
                                                                                    <div className="w-70"> <label>First Name : </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.first_name ? employeeDetail?.first_name : ''}</span> </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="media py-4 mt-0">
                                                                            <div className="media-body">

                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-user-circle"></i> </div>
                                                                                    <div className="w-70"> <label>Middle Name : </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.middle_name ? employeeDetail?.middle_name : "--"}</span> </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-user-tie"></i> </div>
                                                                                    <div className="w-70"> <label>Last Name:</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.last_name ? employeeDetail?.last_name : ''}</span> </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-mail-bulk"></i> </div>
                                                                                    <div className="w-70"> <label>Personal Email :</label> <span className="font-weight-normal1 fs-14" style={{ textTransform: 'none' }}>{employeeDetail?.personal_email ? employeeDetail?.personal_email : ''}</span> </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="media py-4 mt-0">
                                                                            <div className="media-body">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-home fa-fw"></i> </div>
                                                                                    <div className="w-70">
                                                                                        <label>Residential Address :</label>
                                                                                        <span className="font-weight-normal1 fs-14">{employeeDetail?.residental_address ? employeeDetail?.residental_address : ''}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-user-circle"></i> </div>
                                                                                    <div className="w-70"> <label>Residential Period  : </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.period ? employeeDetail?.period : ""}</span> </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-calendar"></i> </div>
                                                                                    <div className="w-70"> <label>Postal Address :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.postal_address ? employeeDetail?.postal_address : '--'}</span> </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="media py-4 mt-0">
                                                                            <div className="media-body">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-home fa-fw"></i> </div>
                                                                                    <div className="w-70">
                                                                                        <label>Postal Period:</label>
                                                                                        <span className="font-weight-normal1 fs-14">{employeeDetail?.postal_period ? employeeDetail?.postal_period : ''}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-address-card"></i></div>
                                                                                    <div className="w-70">
                                                                                        <label>Previous Address :</label>
                                                                                        <span className="font-weight-normal1 fs-14">{employeeDetail?.previus_address ? employeeDetail?.previus_address : '--'}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-calendar"></i> </div>
                                                                                    <div className="w-70"> <label>Previous Period :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.previous_period ? employeeDetail?.previous_period : '--'}</span> </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="media py-4 border-top mt-0">
                                                                            <div className="media-body">

                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-mail-bulk"></i> </div>
                                                                                    <div className="w-70"> <label>Work Email :</label> <span className="font-weight-normal1 fs-14" style={{ textTransform: 'none' }}>{employeeDetail?.work_email ? employeeDetail?.work_email : '--'}</span> </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-phone"></i> </div>
                                                                                    <div className="w-70"> <label>Telephone(H) :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.telephone_home ? employeeDetail?.telephone_home : '--'}</span> </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-phone-alt"></i> </div>
                                                                                    <div className="w-70"> <label>Telephone(W) :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.telephone_work ? employeeDetail?.telephone_work : '--'}</span> </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="media py-4 border-top mt-0">
                                                                            <div className="media-body">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-birthday-cake"></i> </div>
                                                                                    <div className="w-70"> <label>DOB. :</label> <span className="font-weight-normal1 fs-14">
                                                                                        {employeeDetail?.date_of_birth ? employeeDetail?.date_of_birth : "--"}

                                                                                    </span> </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-birthday-cake"></i> </div>
                                                                                    <div className="w-70"> <label>Age. :</label> <span className="font-weight-normal1 fs-14">
                                                                                        {age ? age : "--"}
                                                                                    </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-phone"></i> </div>
                                                                                    <div className="w-70"> <label>Marital Status:</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.martial_status ? employeeDetail?.martial_status : '--'}</span> </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="media py-4 border-top mt-0">
                                                                            <div className="media-body">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-birthday-cake"></i> </div>
                                                                                    <div className="w-70"> <label>No. of Dependents. :</label> <span className="font-weight-normal1 fs-14">
                                                                                        {employeeDetail?.dependent_no ? employeeDetail?.dependent_no : "--"}

                                                                                    </span> </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-birthday-cake"></i> </div>
                                                                                    <div className="w-70"> <label>Occupation. :</label> <span className="font-weight-normal1 fs-14">
                                                                                        {employeeDetail?.occupation ? employeeDetail?.occupation : "--"}
                                                                                    </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-phone"></i> </div>
                                                                                    <div className="w-70"> <label>Current Employer:</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.current_employer ? employeeDetail?.current_employer : '--'}</span> </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="media py-4 border-top mt-0">
                                                                            <div className="media-body">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-birthday-cake"></i> </div>
                                                                                    <div className="w-70"> <label>Employed Since. :</label> <span className="font-weight-normal1 fs-14">
                                                                                        {employeeDetail?.employed_since ? employeeDetail?.employed_since : "***"}

                                                                                    </span> </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-birthday-cake"></i> </div>
                                                                                    <div className="w-70"> <label>Previous Employer. :</label> <span className="font-weight-normal1 fs-14">
                                                                                        {employeeDetail?.previus_employer ? employeeDetail?.previus_employer : "--"}
                                                                                    </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-phone"></i> </div>
                                                                                    <div className="w-70"> <label>Previous Employer Address:</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.previus_office_address ? employeeDetail?.previus_office_address : '--'}</span> </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="media py-4 border-top mt-0">
                                                                            <div className="media-body">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-birthday-cake"></i> </div>
                                                                                    <div className="w-70"> <label>Previous Employer Period. :</label> <span className="font-weight-normal1 fs-14">
                                                                                        {employeeDetail?.previous_employer_period ? employeeDetail?.previous_employer_period : "***"}

                                                                                    </span> </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-birthday-cake"></i> </div>
                                                                                    <div className="w-70"> <label>Own House Estimated Value. :</label> <span className="font-weight-normal1 fs-14">
                                                                                        {employeeDetail?.house_est_value ? employeeDetail?.house_est_value : "--"}
                                                                                    </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-piggy-bank"></i> </div>
                                                                                    <div className="w-70"> <label>Bank Name(Mortgage) :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.mortgaged ? employeeDetail?.mortgaged : '--'}</span> </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="media py-4 border-top mt-0">
                                                                            <div className="media-body">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-male fa-fw"></i> </div>
                                                                                    <div className="w-70"> <label>Monthly Repayment Amount :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.monthly_payment ? employeeDetail?.monthly_payment : '--'}</span> </div>
                                                                                </div>

                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-male fa-fw"></i> </div>
                                                                                    <div className="w-70"> <label>Remaining Mortgage balance :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.mortgage_balance ? employeeDetail?.mortgage_balance : '--'}</span> </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-male fa-fw"></i> </div>
                                                                                    <div className="w-70"> <label>Living House Status:</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.notliving_house ? employeeDetail?.notliving_house : '--'}</span> </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="media py-4 border-top mt-0">
                                                                            <div className="media-body">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-birthday-cake"></i> </div>
                                                                                    <div className="w-70"> <label>Bank Name:</label> <span className="font-weight-normal1 fs-14">
                                                                                        {employeeDetail?.bank_name ? employeeDetail?.bank_name : "--"}

                                                                                    </span> </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-birthday-cake"></i> </div>
                                                                                    <div className="w-70"> <label>Branch:</label> <span className="font-weight-normal1 fs-14">
                                                                                        {employeeDetail?.branch ? employeeDetail?.branch : "--"}

                                                                                    </span> </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-male fa-fw"></i> </div>
                                                                                    <div className="w-70"> <label>Account Holder Name :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.account_name ? employeeDetail?.account_name : '--'}</span> </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="media py-4 border-top mt-0">
                                                                            <div className="media-body">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-house-user"></i> </div>
                                                                                    <div className="w-70"> <label>Account No. :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.account_number ? employeeDetail?.account_number : '--'}</span> </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="profile" title="Income" >
                                    {/* <Expenses /> */}
                                    <div style={{ marginTop: "30px" }}>
                                        <h6>Income</h6>
                                        <div className="main-contact-info-body" style={{ marginTop: "0px" }}>
                                            <div className="media-list p-0">

                                                <div className="media py-4 mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-money-check-alt " /* style={{verticalAlign: "text-top" , marginTop: "-2px"}} */></i> </div>
                                                            <div className="w-70"> <label>Pay Cycle : </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.pay_cycle ? employeeDetail?.pay_cycle : '--'}</span> </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-money-check-alt " /* style={{verticalAlign: "text-top" , marginTop: "-2px"}} */></i> </div>
                                                            <div className="w-70"> <label>Annual Salary : </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.annual_salary ? "$" + employeeDetail?.annual_salary : '--'}</span> </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-money-check-alt"></i> </div>
                                                            <div className="w-70"> <label>Other Monthly Income : </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.other_income ? "$" + employeeDetail?.other_income : '--'}</span> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="media py-4 border-top mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-money-check-alt"></i> </div>
                                                            <div className="w-70"> <label>Total Monthly Salary:</label> <span className="font-weight-normal1 fs-14">
                                                                {/* {employeeDetail?.monthly_income ? "$" + employeeDetail?.monthly_income : '--'} */}
                                                                {Total_Monthly_Income ? "$" + Total_Monthly_Income : '--'}
                                                            </span> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Tab>
                                <Tab eventKey="assest" title="Assets">
                                    {/* <Assets /> */}
                                    <div style={{ marginTop: "30px" }}>
                                        <h6>Assets</h6>
                                        <div className="main-contact-info-body" style={{ marginTop: "0px" }}>
                                            <div className="media-list p-0">

                                                <div className="media py-4 mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-house"></i> </div>
                                                            <div className="w-70"> <label>House Value : </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.house_value ? "$" + employeeDetail?.house_value : '--'}</span> </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-car"></i> </div>
                                                            <div className="w-70"> <label>Car Value : </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.car_value ? "$" + employeeDetail?.car_value : '--'}</span> </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-landmark"></i> </div>
                                                            <div className="w-70"> <label>Land Value :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.land_value ? "$" + employeeDetail?.land_value : '--'}</span> </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="media py-4 border-top mt-0">
                                                    <div className="media-body">

                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-address-card fa-fw"></i> </div>
                                                            <div className="w-70"> <label>Life Policy :
                                                            </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.life_policy ? "$" + employeeDetail?.life_policy : '--'}</span> </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-credit-card"></i> </div>
                                                            <div className="w-70"> <label>debtors :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.debtors ? "$" + employeeDetail?.debtors : '--'}</span> </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-home fa-fw"></i> </div>
                                                            <div className="w-70">
                                                                <label>Personal Effects :</label>
                                                                <span className="font-weight-normal1 fs-14"> {employeeDetail?.personal_effects ? "$" + employeeDetail?.personal_effects : '--'} </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="media py-4 border-top mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-money-check-alt"></i> </div>
                                                            <div className="w-70"> <label>Available Cash :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.cash_on_hand ? "$" + employeeDetail?.cash_on_hand : '--'} </span> </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-money-check-alt"></i></div>
                                                            <div className="w-70">
                                                                <label>Cash At Bank :</label>
                                                                <span className="font-weight-normal1 fs-14">{employeeDetail?.cash_at_bank ? "$" + employeeDetail?.cash_at_bank : '--'}</span>
                                                            </div>
                                                        </div>
                                                        <div className="media py-4 border-top mt-0">
                                                            <div className="media-body">
                                                                <div className="d-flex align-items-center">
                                                                    {/* <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-toolbox"></i> </div>
                                <div className="w-70"> <label>Total Asset :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.total_asset ? "$" + employeeDetail?.total_asset : '--'} </span> </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                    </div>
                                </Tab>
                                <Tab eventKey="liabilities" title="Liabilities">
                                    {/* <Liabilities /> */}

                                    <div style={{ marginTop: "30px" }}>
                                        <h6>Liabilities</h6>
                                        <div className="main-contact-info-body" style={{ marginTop: "0px" }}>
                                            <div className="media-list p-0">
                                                <div className="media py-4 mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-calendar"></i> </div>
                                                            <div className="w-70"> <label>Mortgage Loan Balance : </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.mortgage_loan_balnce ? "$" + employeeDetail?.mortgage_loan_balnce : '--'}</span> </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-home fa-fw"></i> </div>
                                                            <div className="w-70"> <label>Home Loan :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.home_loan ? "$" + employeeDetail?.home_loan : '--'}</span> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="media py-4 border-top mt-0">
                                                    <div className="media-body">

                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1 "> <i className="material-icons mt-1" >&#xe84f;</i> </div>
                                                            <div className="w-70"> <label>Other Loan Balance :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.otherloan_balance ? "$" + employeeDetail?.otherloan_balance : '--'}</span> </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1 "> <i className="material-icons mt-1" >&#xe84f;</i> </div>
                                                            <div className="w-70"> <label>Total Liabilities :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? "$" + employeeDetail?.total_outgoings : ''}</span> </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="media py-4 border-top mt-0">
                        <div className="media-body">
                        <div className="d-flex align-items-center">
                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-location-arrow"></i> </div>
                        <div className="w-70"> <label>Total Liabilities :</label> <span className="font-weight-normal1 fs-14">${employeeDetail ? employeeDetail?.total_outgoings: ''}</span> </div>
                    </div>                            
                </div>
            </div>  */}
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="monthly" title="Monthly Living Cost">
                                    {/* <Monthly /> */}
                                    <div style={{ marginTop: "30px" }}>
                                        <h6>Living Cost</h6>

                                        <div className="main-contact-info-body" style={{ marginTop: "0px" }}>
                                            <div className="media-list p-0">

                                                <div className="media py-4 mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-home"></i> </div>
                                                            <div className="w-70"> <label>Rent : </label> <span className="font-weight-normal1 fs-14"><b>{employeeDetail?.rent ? "$" + employeeDetail?.rent : '--'}</b></span> </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-lightbulb"></i> </div>
                                                            <div className="w-70"> <label>Electricity : </label> <span className="font-weight-normal1 fs-14"><b>{employeeDetail?.electricity ? "$" + employeeDetail?.electricity : '--'}</b></span> </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-phone"></i> </div>
                                                            <div className="w-70"> <label>Telephone Cost :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.bill_for_phone ? "$" + employeeDetail?.bill_for_phone : '--'}</span> </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="media py-4 border-top mt-0">
                                                    <div className="media-body">

                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-water"></i> </div>
                                                            <div className="w-70"> <label>Water :
                                                            </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.bill_for_water ? "$" + employeeDetail?.bill_for_water : '--'}</span> </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-house-user"></i> </div>
                                                            <div className="w-70"> <label>House Keeping :</label> <span className="font-weight-normal1 fs-14"><b>{employeeDetail?.house_keeping ? "$" + employeeDetail?.house_keeping : '--'}</b></span> </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-book-reader"></i> </div>
                                                            <div className="w-70">
                                                                <label>School Fees :</label>
                                                                <span className="font-weight-normal1 fs-14"><b>{employeeDetail?.school_fees ? "$" + employeeDetail?.school_fees : '--'}</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="media py-4 border-top mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-gas-pump"></i> </div>
                                                            <div className="w-70"> <label>Travelling/Petrol/Bus :</label> <span className="font-weight-normal1 fs-14"><b>{employeeDetail?.travelling ? "$" + employeeDetail?.travelling : '--'}</b></span> </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-tv"></i></div>
                                                            <div className="w-70">
                                                                <label>Entertainment/Video :</label>
                                                                <span className="font-weight-normal1 fs-14"><b>{employeeDetail?.entertainment ? "$" + employeeDetail?.entertainment : '--'}</b>  </span>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="material-icons mt-1">&#xe8a1; </i></div>
                                                            <div className="w-70"> <label>Total Hire Purchase Payments :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.hire_payments ? "$" + employeeDetail?.hire_payments : '--'}</span> </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="media py-4 border-top mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-shield-alt"></i> </div>
                                                            <div className="w-70"> <label>Total Hire Purchase Payments:</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.hire_payments ? "$" + employeeDetail?.hire_payments : '--'}</span> </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-shield-alt"></i> </div>
                                                            <div className="w-70"> <label>Life Insurance :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.life_insurance ? "$" + employeeDetail?.life_insurance : '--'}</span> </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            {/* <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-shield-alt"></i> </div> */}
                                                            {/* <div className="w-70"> <label>Life Insurance :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.life_insurance ? "$" + employeeDetail?.life_insurance : '--'}</span> </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Tab>
                                <Tab eventKey="documentss" title="Documents">
                                    {/* <Documents /> */}
                                    <div style={{ marginTop: "30px" }}>
                                        <h6>Documents</h6>
                                        <div className="main-contact-info-body" style={{ marginTop: "0px" }}>
                                            <div className="media-list p-0">
                                                <div className="row row-cols-1 row-cols-md-2 g-4">
                                                    <div className="col">
                                                        <div className="card  " style={{ border: "1px solid silver" }}>
                                                            <div className="card-body" style={{ background: '#4239a4' }}>
                                                                <h3 className="card-title text-light ">Tin Number</h3>
                                                            </div>
                                                            {tin.map((Tin, index) => {
                                                                return (
                                                                    <>
                                                                        {Tin?.file_type === '.pdf' ? (
                                                                            <a href={Tin.doc_file} download>
                                                                                <img
                                                                                    src="/assets/img/pdf.jpeg"
                                                                                    style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                                                    className="card-img-top m-1"
                                                                                    alt="PDF"
                                                                                />
                                                                            </a>
                                                                        ) : (
                                                                            <Image src={Tin ? Tin.doc_file : ''} style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..."
                                                                            />
                                                                        )}

                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="card " style={{ border: "1px solid silver" }} >
                                                            <div className="card-body" style={{ background: '#4239a4' }}>
                                                                <h3 className="card-title text-light ">Signature</h3>
                                                            </div>
                                                            {signature.map((Signature, index) => {
                                                                return (
                                                                    <>
                                                                        {Signature?.file_type === '.pdf' ? (
                                                                            <a href={Signature.doc_file} download>
                                                                                <img
                                                                                    src="/assets/img/pdf.jpeg"
                                                                                    style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                                                    className="card-img-top m-1"
                                                                                    alt="PDF"
                                                                                />
                                                                            </a>
                                                                        ) : (
                                                                            <Image src={Signature ? Signature.doc_file : ''}
                                                                                style={{ width: '70px', height: '100px', border: "1px solid silver" }}
                                                                                className="card-img-top m-1" alt="..." />
                                                                        )}
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row row-cols-1 row-cols-md-2 g-4">
                                                    <div className="col">
                                                        <div className="card  " style={{ border: "1px solid silver" }} >
                                                            <div className="card-body" style={{ background: '#4239a4' }}>
                                                                <h3 className="card-title text-light ">Photo ID</h3>
                                                            </div>
                                                            {photo.map((Photo, index) => {
                                                                return (
                                                                    <>
                                                                        {Photo?.file_type === '.pdf' ? (
                                                                            <a href={Photo.doc_file} download>
                                                                                <img
                                                                                    src="/assets/img/pdf.jpeg"
                                                                                    style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                                                    className="card-img-top m-1"
                                                                                    alt="PDF"
                                                                                />
                                                                            </a>
                                                                        ) : (
                                                                            <Image src={Photo ? Photo.doc_file : ''} style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..." />)}
                                                                    </>
                                                                )
                                                            })}

                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="card" style={{ border: "1px solid silver" }} >
                                                            <div className="card-body" style={{ background: '#4239a4' }}>
                                                                <h3 className="card-title text-light ">Pay Slip</h3>
                                                            </div>
                                                            {pay.map((Pay, index) => {
                                                                return (
                                                                    <>
                                                                        {Pay?.file_type === '.pdf' ? (
                                                                            <a href={Pay.doc_file} download>
                                                                                <img
                                                                                    src="/assets/img/pdf.jpeg"
                                                                                    style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                                                    className="card-img-top m-1"
                                                                                    alt="PDF"
                                                                                />
                                                                            </a>
                                                                        ) : (
                                                                            <Image src={Pay ? Pay.doc_file : ''}
                                                                                style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..." />
                                                                        )}
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row row-cols-1 row-cols-md-2 g-4">
                                                    <div className="col">
                                                        <div className="card  " style={{ border: "1px solid silver" }} >
                                                            <div className="card-body" style={{ background: '#4239a4' }}>
                                                                <h3 className="card-title text-light ">Income Statement</h3>
                                                            </div>
                                                            {income.map((Income, index) => {
                                                                return (
                                                                    <>
                                                                        {Income?.file_type === '.pdf' ? (
                                                                            <a href={Income.doc_file} download>
                                                                                <img
                                                                                    src="/assets/img/pdf.jpeg"
                                                                                    style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                                                    className="card-img-top m-1"
                                                                                    alt="PDF"
                                                                                />
                                                                            </a>
                                                                        ) : (
                                                                            <Image src={Income ? Income.doc_file : ''}
                                                                                style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..."
                                                                            />)}
                                                                    </>
                                                                )
                                                            })}

                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="card " style={{ border: "1px solid silver" }} >
                                                            <div className="card-body" style={{ background: '#4239a4' }}>
                                                                <h3 className="card-title text-light ">Loan Statement</h3>
                                                            </div>
                                                            {loan.map((Loan, index) => {
                                                                return (
                                                                    <>
                                                                        {Loan?.file_type === '.pdf' ? (
                                                                            <a href={Loan.doc_file} download>
                                                                                <img
                                                                                    src="/assets/img/pdf.jpeg"
                                                                                    style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                                                    className="card-img-top m-1"
                                                                                    alt="PDF"
                                                                                />
                                                                            </a>
                                                                        ) : (
                                                                            <Image src={Loan ? Loan.doc_file : ''}
                                                                                style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..." />
                                                                        )}
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row row-cols-1 row-cols-md-2 g-4">
                                                    <div className="col">
                                                        <div className="card  " style={{ border: "1px solid silver" }} >
                                                            <div className="card-body" style={{ background: '#4239a4' }}>
                                                                <h3 className="card-title text-light ">Bank Statement</h3>
                                                            </div>
                                                            {bank.map((Bank, index) => {
                                                                return (
                                                                    <>
                                                                        {Bank?.file_type === '.pdf' ? (
                                                                            <a href={Bank.doc_file} download>
                                                                                <img
                                                                                    src="/assets/img/pdf.jpeg"
                                                                                    style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                                                    className="card-img-top m-1"
                                                                                    alt="PDF"
                                                                                />
                                                                            </a>
                                                                        ) : (
                                                                            <Image src={Bank ? Bank.doc_file : ''} style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..."
                                                                            />
                                                                        )}
                                                                    </>
                                                                )
                                                            })}

                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="card" style={{ border: "1px solid silver" }} >
                                                            <div className="card-body" style={{ background: '#4239a4' }}>
                                                                <h3 className="card-title text-light ">Employment Letter</h3>
                                                            </div>
                                                            {employ.map((Employ, index) => {
                                                                return (
                                                                    <>
                                                                        {Employ?.file_type === '.pdf' ? (
                                                                            <a href={Employ.doc_file} download>
                                                                                <img
                                                                                    src="/assets/img/pdf.jpeg"
                                                                                    style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                                                    className="card-img-top m-1"
                                                                                    alt="PDF"
                                                                                />
                                                                            </a>
                                                                        ) : (
                                                                            <Image src={Employ ? Employ.doc_file : ''}
                                                                                style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..." />
                                                                        )}

                                                                    </>
                                                                )
                                                            })}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                {borrowerAPI && (<Tab eventKey="TXN Detail" title="TXN Detail">
                                    <TxnDetail />
                                </Tab>)}
                                {borrowerAPI && (<Tab eventKey="Attendance" title="Attendance">
                                    <Attendance />
                                </Tab>)}
                                {borrowerAPI && (<Tab eventKey="Wallet" title="Wallet">
                                    <Wallet />
                                </Tab>)}
                                {!borrowerAPI && (<Tab eventKey="ApplicantEMI" title="Applicant Repayment">
                                    {/* <Applicationlogs /> */}
                                    <>
                                        <Toaster position="top-right" reverseOrder={false} />
                                        {!showAddNewCases &&
                                            <div>
                                                <div className="main-container container-fluid px-0">
                                                    <div className="page-header mb-3">
                                                        <div className="page-leftheader">
                                                            <h4 className="page-title mb-0 text-primary">Repayments Detail </h4>
                                                        </div>
                                                        <div className="page-rightheader">
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12"></div>
                                                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                                        </div>
                                                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="card">
                                                                <div className="card-body p-0">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                                            <thead className="border-bottom-0 pt-3 pb-3">
                                                                                <tr>
                                                                                    <th className="font-weight-bold">Repayment docs  </th>
                                                                                    <th className="font-weight-bold">Amount  {/* {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }}> </i>} */}</th>
                                                                                    <th className="font-weight-bold">Late Charges {/*  {sortedBy == "late_charges" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('late_charges') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('late_charges') }}> </i>} */}</th>
                                                                                    <th className="font-weight-bold">Repayment No.  {/* {sortedBy == "emi_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('emi_number') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('emi_number') }}> </i>} */}</th>
                                                                                    <th className="font-weight-bold">Paid Amount {/* {sortedBy == "total_paid_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('total_paid_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('total_paid_amount') }}> </i>} */}</th>
                                                                                    <th className="font-weight-bold">Status  {/* {sortedBy == "status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('status') }}> </i>} */}</th>
                                                                                    <th className="font-weight-bold">Due Date  {/* {sortedBy == "due_date" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('due_date') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('due_date') }}> </i>} */}</th>
                                                                                    <th className="font-weight-bold">Paid Date  {/* {sortedBy == "paid_date" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('paid_date') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('paid_date') }}> </i>} */}</th>
                                                                                    {/* <th className="font-weight-bold">Notify</th> */}
                                                                                </tr>
                                                                            </thead>
                                                                            {loader == true ? <tbody><td /><Loader /></tbody> :
                                                                                <>
                                                                                    <tbody>
                                                                                        {EMIdata.length > 0 && EMIdata.map((option, index) => {
                                                                                            let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                                            const { emi_docs, amount, late_charges, due_date, emi_number, total_paid_amount, status, paid_date } = option;

                                                                                            return (
                                                                                                <tr key={serial_num} >
                                                                                                    {emi_docs != null ?
                                                                                                        <td><span className="font-weight-normal1"><img style={{ width: 50, height: 50, borderRadius: '8px' }} src={emi_docs} alt='...' /></span></td> :
                                                                                                        <td><span className="font-weight-normal1" ><img style={{ width: 50, height: 50, borderRadius: '8px' }} src={process.env.PUBLIC_URL + '/assets/img/Fintranxect-Logo-1.png'} alt='...' /></span></td>}
                                                                                                    <td><span className="font-weight-normal1">{option?.amount ? "$" + amount : "--"}</span></td>
                                                                                                    <td><span className="font-weight-normal1">{option?.late_charges ? "$" + late_charges : "--"}</span></td>
                                                                                                    <td><span className="font-weight-normal1">{option?.emi_number ? emi_number : "--"}</span></td>
                                                                                                    <td><span className="font-weight-normal1">{option?.total_paid_amount ? "$" + total_paid_amount : "--"}</span></td>
                                                                                                    <td><span className="font-weight-normal1">{option?.status ? status : "--"}</span></td>
                                                                                                    <td><span className="font-weight-normal1">{/* {timezone} */}
                                                                                                        {TimeZone(option.due_date).timeZone}</span></td>
                                                                                                    <td><span className="font-weight-normal1">{option?.paid_date ? paid_date : "--"}</span></td>
                                                                                                </tr>
                                                                                            )
                                                                                        }
                                                                                        )}
                                                                                    </tbody>
                                                                                    <tbody>
                                                                                        {EMIdata.length == 0 &&
                                                                                            <tr>
                                                                                                <td colSpan={10} className='text-center'>No Data Available</td>
                                                                                            </tr>
                                                                                        }
                                                                                    </tbody>
                                                                                </>}
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* {pagesCount > 0 && dataArray.length > 0 ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''} */}

                                                    </div>
                                                </div>
                                            </div>

                                        }
                                    </>
                                </Tab>)}
                                {!borrowerAPI && (<Tab eventKey="Applicationlogs" title="Activity Logs">
                                    {/* <Applicationlogs /> */}
                                    <>
                                        <Toaster position="top-right" reverseOrder={false} />
                                        {!showAddNewCases &&
                                            <div>
                                                <div className="main-container container-fluid px-0">
                                                    <div className="page-header mb-3">
                                                        <div className="page-leftheader">
                                                            <h4 className="page-title mb-0 text-primary">EWA Advance </h4>
                                                        </div>
                                                        <div className="page-rightheader">
                                                            <div className="btn-list">
                                                                <DownloadTableExcel
                                                                    filename="users table"
                                                                    sheet="users"
                                                                    currentTableRef={tableRef.current}>
                                                                    <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i> Download Details</Link>
                                                                </DownloadTableExcel>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">

                                                            {/* <div className="form-group">
                                    <label className="form-label">All Status</label>
                                    <div className="row g-xs">
                                        <div className="wd-200 mg-b-30">
                                            <div className="input-group">
                                                <select className="form-control mb-0" name='submit'  value={setQueryFilter.filter}  onChange={(e) => handleChangeQueryBuilder(e.target.value)}>
                                                    <option>Select Status</option>
                                                    <option value="all task" >All Status</option>
                                                    <option value="Assigned"  >Assigned</option>
                                                    <option value="Unassigned" >Unassigned</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                                        </div>
                                                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                                        </div>
                                                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                                            <div className="form-group">
                                                                <label className="form-label">Search</label>
                                                                <div className="row g-xs">
                                                                    <div className="input-group">
                                                                        <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                                                        <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="card">
                                                                <div className="card-body p-0">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                                            <thead className="border-bottom-0 pt-3 pb-3">
                                                                                <tr>
                                                                                    <th className="font-weight-bold">Updated By  {sortedBy == "id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('id') }}> </i>}</th>
                                                                                    <th className="font-weight-bold">New Status  {sortedBy == "new_status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('new_status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('new_status') }}> </i>}</th>
                                                                                    <th className="font-weight-bold">Activity Date  {sortedBy == "sub_lender_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('sub_lender_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('sub_lender_id') }}> </i>}</th>                                                    </tr>
                                                                            </thead>
                                                                            {loader == true ? <tbody><td /><Loader /></tbody> :
                                                                                <>
                                                                                    <tbody>
                                                                                        {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                                            let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                                            const { employer_name, new_status } = option;
                                                                                            // var num2 = parseFloat(option.interest_rate).toFixed(2)
                                                                                            // var Capitalize = (option) => {
                                                                                            //     return option.charAt(0).toUpperCase() + option.slice(1);
                                                                                            //     }


                                                                                            return (
                                                                                                <tr key={serial_num} >
                                                                                                    <td><Link className="text-primary" >
                                                                                                        <span className="font-weight-normal1">{option?.subLenderData?.full_name ? option?.subLenderData?.full_name : ""}</span></Link></td>
                                                                                                    <td><span className="font-weight-normal1">{option?.new_status ? new_status : ""}</span></td>
                                                                                                    <td><span className="font-weight-normal1">{TimeZone(option.created_at).timeZone}{/* {timezone} */}</span></td>
                                                                                                </tr>
                                                                                            )
                                                                                        }
                                                                                        )}
                                                                                    </tbody>
                                                                                    <tbody>
                                                                                        {dataArray.length == 0 &&
                                                                                            <tr>
                                                                                                <td colSpan={10} className='text-center'>No Data Available</td>
                                                                                            </tr>
                                                                                        }
                                                                                    </tbody>
                                                                                </>}
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {pagesCount > 0 && dataArray.length > 0 ?
                                                            <>
                                                                <div className="col-md-12">
                                                                    <div className="card-body">
                                                                        <PaginationComponent className="justify-content-center"
                                                                            totalItems={totalPagesCount}
                                                                            pageSize={10}
                                                                            maxPaginationNumbers={3}
                                                                            onSelect={(e) => setpagesCount(e)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : ''}

                                                    </div>
                                                </div>
                                            </div>

                                        }
                                    </>
                                </Tab>)}

                            </Tabs>
                        </div>
                    </div>
                    <StepBar />
                    <div className="modal-body application-modal">
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeDetails